import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/blocks/atoms/Product/ClientProduct.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/blocks/atoms/Product/ProductUI.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/blocks/layout/ArticleCategory/ClientArticleCategory.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/blocks/layout/ArticleCategory/components/ArticleCategoryTabs.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/blocks/sections/ArticleHero/ArticleHero.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/blocks/sections/ContentCards/components/ContentSlides.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/blocks/sections/HubspotForm/HubspotForm.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/blocks/sections/Media/Media.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/blocks/statics/header/HeaderBloks.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/blocks/statics/header/MegaHeaderBloks.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/ui/Accordion/Accordion.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/ui/BlokWrap/BlokWrap.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/ui/Breadcrumbs/breadcrumbs.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/ui/Button/Button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/ui/Navbar/AccountItem.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/ui/Navbar/FavouriteItem.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/ui/Navbar/index.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/ui/Navbar/NavbarWrap.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/ui/Navbar/SearchItem.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/ui/Video/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/features/cart/components/Cart/CartToggle.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/features/product-listing/components/PlpTop/CategoryTags.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/features/product-listing/components/ProductListingPage/index.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/features/product-listing/components/PlpTop/index.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/components/ui/Tabs/index.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/features/product/components/ClientPlp/ClientPlp.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/features/product/components/ProductCard/ProductCard.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/features/product/components/ProductCardList/ProductSlides.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/lib/storyblok/StoryblokComponent/ClientComponent.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/apps/blaest/src/lib/storyblok/StoryblokStory/ClientStoryblokStory.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@storyblok+react@2.4.8_react-dom@18.2.0_react@18.2.0/node_modules/@storyblok/react/dist/bridge-loader.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@storyblok+react@2.4.8_react-dom@18.2.0_react@18.2.0/node_modules/@storyblok/react/dist/story.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.28.14_react@18.2.0/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.28.14_react@18.2.0/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.28.14_react@18.2.0/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.28.14_react@18.2.0/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.28.14_react@18.2.0/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.28.14_react@18.2.0/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.28.14_react@18.2.0/node_modules/@tanstack/react-query/build/modern/useMutation.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.28.14_react@18.2.0/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.28.14_react@18.2.0/node_modules/@tanstack/react-query/build/modern/useQueries.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.28.14_react@18.2.0/node_modules/@tanstack/react-query/build/modern/useQuery.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.28.14_react@18.2.0/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.28.14_react@18.2.0/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@tanstack+react-query@5.28.14_react@18.2.0/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-intl@3.11.1_next@14.1.4_react@18.2.0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.4_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js")